<template>
  <div>Yearling</div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
